import { ViewRoomPayload as MatrixViewRoomPayload } from "matrix-react-sdk/src/dispatcher/payloads/ViewRoomPayload";
import { MatrixEvent } from "matrix-js-sdk/src/matrix";
import { Member } from "../../utils/direct-messages";

export type ViewRoomPayload = MatrixViewRoomPayload & {
    editingToEvent?: MatrixEvent;
    roomType?: RoomDMType;
    targets: Member[];
};

export enum RoomDMType {
    VIEW_ROOM = "view_room",
    VIEW_CHECK_ROOM_FAILED = "view_check_room_failed",
}
