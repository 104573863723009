export enum EEventType {
    ModularWidgets = 'ctalk.modular.widgets',
    AutoDelete = 'c.room.auto_delete',
    RoomRetention = 'c.room.retention',
    ChatFolder = 'c.chat_folder',
}
export enum EEventContentType {
    JitsiV1 = 'jitsi',
    JitsiV2 = 'm.jitsi',
}

export enum ECTalkRoomStateEvent {
    RoomRetention = 'c.room.retention',
    AutoDelete = 'c.room.auto_delete',
}
